<template>
	<div class="body">
		<div class="search">
			<Form :model="searchForm" inline @submit.native.prevent :label-width="75" :label-colon="true">
				<FormItem :label-width="0">
					<Button type="primary" icon="md-add" @click="openModal">添加签名</Button>
				</FormItem>
				<FormItem :label-width="10">
					<Button type="primary" icon="md-refresh" @click="getSignStatus">更新审核状态</Button>
				</FormItem>
			</Form>
		</div>
		<div class="table-item">
			<Table :data="data" :loading="table_loading" :columns="columns">
				<template slot-scope="{row}" slot="operation">
					<a @click="edit(row.id)" style="margin-right: 10px;">编辑</a>
					<a @click="del(row.id)">删除</a>
				</template>
			</Table>
			<Page class-name="page-r" :total="searchForm.total" :current="searchForm.page"
				:page-size="searchForm.pageSize" :page-size-opts="[15,30,50]" show-total show-sizer
				@on-change="set_current" @on-page-size-change="set_per"></Page>
		</div>
		<Modal v-model="modal" :title="is_modify?'编辑签名':'添加签名'">
			<Form :model="formData" :label-width="100" :rules="rules" ref="ruleForm">
				<FormItem label="选择地区" prop="Region">
					<Select v-model="formData.Region" style="width: 230px;">
						<Option v-for="(item,index) in RegionItem" :key="index" :value="item.value">{{item.label}}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="签名类型" prop="SignType">
					<Select v-model="formData.SignType" style="width: 230px;" @on-change="changeSignType">
						<Option v-for="(item,index) in SignType" :key="index" :value="item.value">{{item.label}}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="签名内容" prop="SignName">
					<Input v-model="formData.SignName" placeholder="请输入机构名的全称或简称"></Input>
					<div class="tips">长度为2~12字，由中英文、数字组成，内容不包含【】。范例：寻疗健康</div>
				</FormItem>
				<FormItem v-if="formData.DocumentType!=null" label="证明类型" prop="DocumentType">
					<Select v-model="formData.DocumentType" style="width: 230px;">
						<Option v-for="(item,index) in DocumentTypeItem[formData.SignType].children" :key="index"
							:value="item.value">{{item.label}}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="证明上传" prop="ProofImage">
					<div class="thumb" v-if="formData.ProofImage">
						<img v-if="formData.ProofImage" :src="ImgUrl+formData.ProofImage" alt="" />
						<a href="javascript:;" class="del-icon" @click="formData.ProofImage=''">x</a>
					</div>
					<Uploads :action="ApiUrl+'/adm/upload_img'" :data="{dir:'img_temp',type:'base64'}"
						style="display: inline-block;" @upload_succ="upload_succ"></Uploads>
					<div class="tips">请上传 png、jpg、jpeg 格式的图片，大小在5M内</div>
				</FormItem>
				<FormItem label="授权委托" prop="CommissionImage">
					<div class="thumb" v-if="formData.CommissionImage">
						<img v-if="formData.CommissionImage" :src="ImgUrl+formData.CommissionImage" alt="" />
						<a href="javascript:;" class="del-icon" @click="formData.CommissionImage=''">x</a>
					</div>
					<Uploads :action="ApiUrl+'/adm/upload_img'" :data="{dir:'img_temp',type:'base64'}"
						style="display: inline-block;" @upload_succ="upload_img"></Uploads>
					<div class="tips">若短信签名用途为他用，涉及第三方权益需上传 <a
							href="https://attachment-1252075342.cosgz.myqcloud.com/%E6%8E%88%E6%9D%83%E5%A7%94%E6%89%98%E4%B9%A6.docx">授权委托书</a>
						。请填写相关信息，盖章后拍照上传，支持 png、jpg、jpeg 格式。
					</div>
				</FormItem>
				<!--				<FormItem label="国际/港澳台" prop="International">-->
				<!--					<RadioGroup v-model="formData.International">-->
				<!--						<Radio v-for="(item,index) in InternationalItem" :key="index" :label="item.value">{{item.label}}</Radio>-->
				<!--					</RadioGroup>-->
				<!--				</FormItem>-->
				<FormItem label="申请说明">
					<Input type="textarea" v-model="formData.Remark" :rows="4" placeholder="请输入签名申请用途（选填）"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="AddSmsSign">确定</Button>
				<Button @click="modal=false">取消</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import Uploads from "@/components/common/Uploads";

	export default {
		name: "SmsignList",
		components: {
			Uploads
		},
		data() {
			return {
				ApiUrl: this.ApiUrl,
				ImgUrl: this.ImgUrl,
				modal: false,
				is_modify: 0,
				table_loading: false,
				//搜索条件
				searchForm: {
					status: 2, //状态
					page: 1, //当前页
					pageSize: 15, //当前条数
					total: 0 //总条数
				},
				// 支持的地域列表
				RegionItem: [{
						value: 'ap-beijing',
						label: '华北地区(北京)'
					},
					{
						value: 'ap-guangzhou',
						label: '华南地区(广州)'
					},
					{
						value: 'ap-nanjing',
						label: '华东地区(南京)'
					},
				],
				// 签名类型
				SignType: [{
						value: 0,
						label: '公司'
					},
					{
						value: 1,
						label: 'APP'
					},
					{
						value: 2,
						label: '网站'
					},
					{
						value: 3,
						label: '公众号或者小程序'
					},
					{
						value: 4,
						label: '商标'
					},
					{
						value: 5,
						label: '政府/机关事业单位/其他机构'
					},
				],
				DocumentType: [{
						value: 0,
						label: '三证合一'
					},
					{
						value: 1,
						label: '企业营业执照'
					},
					{
						value: 2,
						label: '组织机构代码证书'
					},
					{
						value: 3,
						label: '社会信用代码证书'
					},
					{
						value: 4,
						label: '应用后台管理截图（个人开发APP）'
					},
					{
						value: 5,
						label: '网站备案后台截图（个人开发网站）'
					},
					{
						value: 6,
						label: '小程序设置页面截图（个人认证小程序）'
					},
					{
						value: 7,
						label: '商标注册书'
					}
				],
				// 证明类型
				DocumentTypeItem: [{
						children: [{
								value: 0,
								label: '三证合一'
							},
							{
								value: 1,
								label: '企业营业执照'
							},
							{
								value: 2,
								label: '组织机构代码证书'
							},
							{
								value: 3,
								label: '社会信用代码证书'
							}
						]
					},
					{
						children: [{
								value: 0,
								label: '三证合一'
							},
							{
								value: 1,
								label: '企业营业执照'
							},
							{
								value: 2,
								label: '组织机构代码证书'
							},
							{
								value: 3,
								label: '社会信用代码证书'
							},
							{
								value: 4,
								label: '应用后台管理截图（个人开发APP）'
							}
						]
					},
					{
						children: [{
								value: 0,
								label: '三证合一'
							},
							{
								value: 1,
								label: '企业营业执照'
							},
							{
								value: 2,
								label: '组织机构代码证书'
							},
							{
								value: 3,
								label: '社会信用代码证书'
							},
							{
								value: 5,
								label: '网站备案后台截图（个人开发网站）'
							}
						]
					},
					{
						children: [{
								value: 0,
								label: '三证合一'
							},
							{
								value: 1,
								label: '企业营业执照'
							},
							{
								value: 2,
								label: '组织机构代码证书'
							},
							{
								value: 3,
								label: '社会信用代码证书'
							},
							{
								value: 6,
								label: '小程序设置页面截图（个人认证小程序）'
							}
						]
					},
					{
						children: [{
							value: 7,
							label: '商标注册书'
						}]
					},
					{
						children: [{
								value: 2,
								label: '组织机构代码证书'
							},
							{
								value: 3,
								label: '社会信用代码证书'
							}
						]
					},
				],
				// 是否国际/港澳台短信
				InternationalItem: [{
						value: 0,
						label: '国内短信'
					},
					{
						value: 1,
						label: '国际/港澳台短信'
					},
				],
				// 签名用途
				SignPurposeItem: [{
						value: 0,
						label: '自用'
					},
					{
						value: 1,
						label: '他用'
					},
				],
				//添加或编辑的数据
				formData: {
					Region: 'ap-guangzhou',
					SignName: '',
					SignType: null,
					DocumentType: null,
					International: 0, //默认国内短信
					SignPurpose: 1, //默认他用
					ProofImage: '', //证明
					CommissionImage: '', //授权委托书
					Remark: ''
				},
				//验证规则
				rules: {
					Region: [{
						required: true,
						type: 'string',
						message: '请选择地域',
						trigger: 'change'
					}],
					SignType: [{
						required: true,
						type: 'number',
						message: '请选择签名类型',
						trigger: 'change'
					}],
					SignName: [{
						required: true,
						type: 'string',
						message: '请输入签名',
						trigger: 'blur'
					}],
					DocumentType: [{
						required: true,
						type: 'number',
						message: '请输入签名',
						trigger: 'change'
					}],
					ProofImage: [{
						required: true,
						type: 'string',
						message: '请上传证明',
						trigger: 'change'
					}],
					CommissionImage: [{
						required: true,
						type: 'string',
						message: '请上传授权委托',
						trigger: 'change'
					}],
				},
				data: [], //table数据
				//表头
				columns: [{
						title: 'ID',
						key: 'id',
						width: 60
					},
					{
						title: '签名ID',
						key: 'sign_id',
						width: 100
					},
					{
						title: '签名名称',
						key: 'sign_name',
						minWidth: 120
					},
					{
						title: '签名类型',
						key: 'sign_type',
						minWidth: 120,
						render: (h, params) => {
							return h('div', this.SignType[params.row.sign_type].label)
						}
					},
					{
						title: '证明类型',
						key: 'document_type',
						minWidth: 120,
						render: (h, params) => {
							return h('div', this.DocumentType[params.row.document_type].label)
						}
					},
					{
						title: '状态/原因',
						key: 'status',
						minWidth: 90,
						render: (h, params) => {
							return h('Tooltip', {
								props: {
									transfer: true,
									placement: 'top',
									content: params.row.status == 0 ? '审核已通过' : (params.row.review_reply ?
										params.row.review_reply : '审核中'),
									maxWidth: 250
								}
							}, [
								h('Tag', {
									props: {
										color: params.row.status == 0 ? 'success' : (params.row
											.status == 1 ? 'warning' : 'error')
									}
								}, params.row.status == 0 ? '审核通过' : (params.row.status == 1 ? '审核中' :
									'审核失败'))
							])
						}
					},
					{
						title: '申请说明',
						key: 'remark',
						minWidth: 150
					},
					{
						title: '创建时间',
						key: 'create_date',
						minWidth: 150
					},
					{
						title: '操作',
						slot: 'operation',
						align: 'center',
						minWidth: 160
					}
				],
			}
		},
		created() {
			this.init()
		},
		methods: {
			getSignStatus() {
				var _this = this;
				this.requestApi('/adm/get_sm_sign_status', {
					data: this.searchForm
				}).then(function(res) {
					if (res.status) {
						_this.alertSucc(res.msg);
						_this.init();
					} else {
						_this.alertErr(res.msg);
					}
				})
			},
			// 添加短信签名
			AddSmsSign() {
				var _this = this;
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						_this.requestApi('/adm/add_sms_sign', {
							type: _this.is_modify,
							data: _this.formData
						}).then(function(res) {
							if (res.status) {
								_this.modal = false;
								_this.alertSucc(res.msg);
								_this.init();
							} else {
								_this.alertErr(res.msg);
							}
						})
					} else {
						_this.alertErr('请完善信息后再提交！')
					}
				})
			},
			changeSignType(value) {
				this.formData.SignType = value;
				this.formData.DocumentType = this.DocumentTypeItem[value].children[0].value;
			},
			// 上传资质证明图片
			upload_succ(res) {
				this.formData.ProofImage = res.pic_url;
			},
			// 委托授权证明
			upload_img(res) {
				this.formData.CommissionImage = res.pic_url;
			},
			openModal() {
				this.formData = {
					Region: 'ap-guangzhou',
					SignName: '',
					SignType: null,
					DocumentType: null,
					International: 0, //默认国内短信
					SignPurpose: 1, //默认他用
					ProofImage: '', //证明
					CommissionImage: '', //授权委托书
					Remark: ''
				}
				this.$refs.ruleForm.resetFields();
				this.is_modify = 0;
				this.modal = true;
			},
			init() {
				var _this = this;
				this.table_loading = true;
				this.requestApi('/adm/get_sign_list', {
					data: this.searchForm
				}).then(function(res) {
					_this.table_loading = false;
					if (res.status) {
						_this.data = res.data.data;
						_this.searchForm.total = res.data.total;
					}
				})
			},
			//切换页数
			set_current(page) {
				this.searchForm.page = page;
				this.init()
			},
			//切换条数
			set_per(size) {
				this.searchForm.page = 1;
				this.searchForm.pageSize = size;
				this.init()
			},
			edit(id) {
				var _this = this;
				this.requestApi('/adm/get_find_sign', {
					id: id
				}).then(function(res) {
					if (res.status) {
						_this.formData.Region = res.data.region;
						_this.formData.SignId = res.data.sign_id;
						_this.formData.SignName = res.data.sign_name;
						_this.formData.SignType = res.data.sign_type;
						_this.formData.DocumentType = res.data.document_type;
						_this.formData.International = res.data.international;
						_this.formData.SignPurpose = res.data.sign_purpose;
						_this.formData.ProofImage = res.data.proof_image;
						_this.formData.CommissionImage = res.data.commission_image;
						_this.formData.Remark = res.data.remark;
						_this.is_modify = 1;
						_this.modal = true;
					} else {
						_this.alertErr(res.msg)
					}
				})

			},
			//删除签名
			del(id) {
				var _this = this;
				this.$Modal.confirm({
					title: '提示',
					content: '确定删除该签名吗？',
					onOk: () => {
						this.requestApi('/adm/del_sms_sign', {
							id
						}).then((res) => {
							if (res.status) {
								_this.alertSucc(res.msg);
								_this.init();
							} else {
								_this.alertErr(res.msg);
							}
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.body {
		padding: 16px;
		background-color: #fff;
	}

	.search {
		background-color: rgb(248, 248, 249);
		display: flex;
		padding: 5px;
		margin-bottom: 10px;
	}

	.page-r {
		margin-top: 10px;
		text-align: right;
	}

	.success {
		color: rgb(25, 190, 107);
	}

	.primary {
		color: rgb(45, 140, 240);
	}

	.error {
		color: rgb(237, 64, 20);
	}

	.tips {
		line-height: 1.5;
		font-size: 12px;
		color: rgba(0, 0, 0, .4);
		vertical-align: middle;
		margin-top: 8px;
	}

	.thumb {
		float: left;
		display: inline-block;
		margin-right: 12px;
		width: 75px;
		height: 75px;
		position: relative;
	}

	.thumb img {
		max-width: 100%;
	}

	.del-icon {
		position: absolute;
		right: 0;
		top: 0;
		display: inline-block;
		width: 16px;
		height: 16px;
		line-height: 13px;
		font-size: 13px;
		color: #999999;
		text-align: center;
		border: 1px solid #ededed;
		background: #FFFFFF;
		border-radius: 50%;
	}
</style>